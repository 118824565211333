<template>

    <v-card v-scroll="onScroll" class="albumCard">
        <v-snackbar
            v-if="isLoading"
            v-model="isLoading"

            :top="true"
            color="info"
        >Loading...
        </v-snackbar>

        <v-snackbar
            v-if="error"
            v-model="error"
            :timeout="5000"
            :top="true"
            color="error"
        >
            <v-icon>mdi-alert</v-icon>
            {{ error }}
        </v-snackbar>
        <v-snackbar
            v-if="deleteError"
            v-model="deleteError"
            :timeout="5000"
            :top="true"
            color="error"
        >
            <v-icon>mdi-delete</v-icon>
            {{ deleteError }}
        </v-snackbar>



        <v-card
            class="mx-auto"
            v-if="item"

        ><v-tooltip left v-if="item">
            <template v-slot:activator="{ on: onTooltip }">
                <v-btn @click.stop="closeWin" icon
                       class="closeWinButton"
                       right
                       fixed
                       v-on="onTooltip">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
            <span>{{ $t('app.Close') }}</span>
        </v-tooltip>
            <div
                id="albumThumbnail"
                class="albumThumbnail"
                v-if="item"
            >
                <img :alt="item['headline']"
                     ref="albumThumbnailImage"
                     class="elevation-5"
                     @load="onScroll"
                     :src="entrypoint+'/image_galleries/'+item['thumbnail']+'/big.jpeg'"
                />
            </div>
            <div class="text-justify px-2"
                 style="margin-top:-20px"
                 v-if="item"
            >
                <h1 class="mb-2">{{ item && item['headline'] }}</h1>
                <div class="album">


                    <p class="album-description pb-4" v-html="item['description']"></p>
                    <p v-if="item['text'] && (item['text'].length<100 || showAll)" class="album-text" v-html="item['text']"></p>
                    <span v-if="item['text'] && item['text'].length>100 && !showAll">
                        <p class="album-text" v-html="item['text'].substring(0, 100) + '...'"></p>
                        <v-btn small @click="showAll=true">Read more</v-btn>
                    </span>

                </div>
                <div class="album-caption"><p v-if="item['place'] && item['place']['name']">
                    {{ $t('app.Photograph.Place') }}: {{ item['place']['name'] }}</p>
                    <!--<p v-if="item.fromYear && item.toYear">{{ $t('app.Photograph.Period') }}:
                        {{ item.fromYear }}<span
                            v-if="item.fromYear!==item.toYear"> - {{ item.toYear }}</span>
                    </p>-->
                    <span v-if="typeof item['categories'] !=='undefined' && item['categories'].length>0">{{ $t('app.Categories') }}:
                            <v-chip small v-for="(category, i) in item['categories']"
                                    :key="i"
                                    class="ma-2"
                                    label
                                    @click="setCategoryFilter(category)"
                            >{{ getCategoryById(category) ? getCategoryById(category).name : '' }}
                            </v-chip>
                        </span>
                </div>
                <v-expansion-panels hover v-if="attributes.filter((a)=>{ return typeof item.categoryAttributes !== 'undefined' && item.categoryAttributes !== null && typeof item.categoryAttributes[a.code] !== 'undefined' && item.categoryAttributes[a.code] !== null && item.categoryAttributes[a.code] !== ''}).length>0" style="z-index: auto">
                    <v-expansion-panel>
                        <v-expansion-panel-header color="cardbg">{{ $t('app.AdditionalDatas') }}</v-expansion-panel-header>
                        <v-expansion-panel-content color="cardbg">
                            <v-list two-line v-if="attributes"  color="cardbg">
                                <v-list-item
                                    v-for="attribute in attributes.filter((a)=>{ return typeof item.categoryAttributes !== 'undefined' && item.categoryAttributes !== null && typeof item.categoryAttributes[a.code] !== 'undefined' && item.categoryAttributes[a.code] !== null && item.categoryAttributes[a.code] !== ''})"
                                    :key="attribute.code" ripple
                                    :to="attribute.type==='Location' ? {name: 'MapShowGeo', params: { geo: item.categoryAttributes[attribute.code].lat+','+item.categoryAttributes[attribute.code].lon }} : null"
                                >
                                    <v-list-item-content>
                                        <v-list-item-title v-html="attribute.name"></v-list-item-title>
                                        <v-list-item-subtitle v-if="attribute.type==='Location'"
                                                              v-html="item.categoryAttributes[attribute.code].display_name"
                                        ></v-list-item-subtitle>
                                        <v-list-item-subtitle v-else-if="attribute.type==='AlbumLink'">
                                            <v-btn small :to="{name: 'ImageGalleryShow', params: {id: item.categoryAttributes[attribute.code]['@id']}}">{{item.categoryAttributes[attribute.code].headline}}</v-btn>
                                        </v-list-item-subtitle>
                                        <v-list-item-subtitle v-else
                                                              v-html="item.categoryAttributes[attribute.code]"
                                        ></v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-avatar>
                                        <v-icon v-if="attribute.type==='Location'">mdi-map</v-icon>
                                        <v-icon v-if="attribute.type==='Date'">mdi-calendar</v-icon>
                                        <v-icon v-if="attribute.type==='Time'">mdi-clock</v-icon>
                                    </v-list-item-avatar>
                                </v-list-item>

                            </v-list>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
                <div><p v-if="typeof item['owner'] !== 'undefined' && item['owner'] !== null" class="text-caption mt-5"><!--{{ $t(item['isPrivate'] ? 'app.privateVisible' : 'app.publicVisible') }} | {{
                        $t('app.Created')
                    }}:
                    {{ $d(Date.parse(item['dateCreated']), 'long') }}--> <!--Dieses Album gehört {{
                        (typeof item['owner'] !== 'undefined' && item['owner'] !== null) ? item['owner']['name'] : '-'
                    }}--><!-- | <span v-if="item['dateModified']">{{ $t('app.Modified') }}:
                    {{ $d(Date.parse(item['dateModified']), 'long') }}</span>--></p></div>

            </div>
        </v-card>


        <div class="text-justify pa-1" style="margin-bottom: 100px"
             v-if="item"
        >


            <div
                v-if="(typeof item['photographs']!=='undefined' && item['photographs'].length>0) || photographComments.length>0 || (typeof item['retrosnaps']!=='undefined' && item['retrosnaps'].length>0) || (typeof item['subGalleries']!=='undefined' && item['subGalleries'].length>0)"
                class="masonry-wrapper" style="margin-bottom:60px;">
                <div class="masonry" >

                    <div
                        :class="'masonry-item'+getRatioClass(slide)"
                        v-for="(slide) in item['subGalleries']"
                        :key="slide['@id']"
                    >


                        <v-img
                            v-touch="{ left: () => setActiveTouchEle(slide),  right: () => setActiveTouchEle(slide), up: () => setActiveTouchEle(slide), down: () => setActiveTouchEle(slide)}"

                            class="masonry-content"
                            :aspect-ratio="1"
                            :src="entrypoint+'/image_galleries/'+slide.thumbnail+'/display'+(webpSupported ?'.webp' : '.jpeg')"
                            :lazy-src="entrypoint+'/image_galleries/'+slide.thumbnail+'/lazy'+(webpSupported ?'.webp' : '.jpeg')"

                            @dblclick.stop="carouselItemClick(slide['@id'])"
                            @click.stop="carouselItemClick(slide['@id'])"
                        >
                        </v-img>
                        <v-card-actions
                            transition="fade-transition"
                            class="homevcardactions">
                            <v-spacer></v-spacer>
                            <v-tooltip top
                                       v-if="personItem && item.owner && personItem['@id']===item.owner['@id'] && item && item['@type']==='https://schema.org/ImageGallery'">
                                <template v-slot:activator="{ on: onTooltip }">
                                    <v-btn
                                        @click.native.stop.prevent="removeGalleryFromGallery(slide)"
                                        v-on="onTooltip"
                                        icon>
                                        <v-icon>mdi-image-remove</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('app.Gallery.RemoveFromGallery') }}</span>
                            </v-tooltip>
                        </v-card-actions>
                    </div>
                    <div
                        :class="'masonry-item'+getRatioClass(slide)"
                        v-for="(slide) in item['photographs']"
                        :key="slide['@id']"

                    >


                        <v-img v-if="(!slide.depthMapPath || !show25DPhotos)"
                               v-touch="{ left: () => setActiveTouchEle(slide),  right: () => setActiveTouchEle(slide), up: () => setActiveTouchEle(slide), down: () => setActiveTouchEle(slide)}"

                               class="masonry-content"
                               :aspect-ratio="getRatioRounded(slide)"
                               :src="entrypoint+'/thumbnail/'+(showColorized ? 'colorized.' : '')+'list/'+slide.filePath.replace(/\.jpeg/,(webpSupported ?'.webp' : '.jpeg'))+
                               (slide.thumbnailCoordinates && slide.thumbnailCoordinates.feed ? '?c='+slide.thumbnailCoordinates.feed.left+','+slide.thumbnailCoordinates.feed.top+','+slide.thumbnailCoordinates.feed.width+','+slide.thumbnailCoordinates.feed.height+',' : '')"
                               :lazy-src="entrypoint+'/thumbnail/'+(showColorized ? 'colorized.' : '')+'lazy/'+slide.filePath.replace(/\.jpeg/,(webpSupported ?'.webp' : '.jpeg'))+
                               (slide.thumbnailCoordinates && slide.thumbnailCoordinates.feed ? '?c='+slide.thumbnailCoordinates.feed.left+','+slide.thumbnailCoordinates.feed.top+','+slide.thumbnailCoordinates.feed.width+','+slide.thumbnailCoordinates.feed.height+',' : '')"

                               @dblclick.stop="carouselItemClick(slide['@id'])"
                               @click.stop="carouselItemClick(slide['@id'])"
                        >
                            <v-img v-if="slide.retrosnaps.length>0"
                                   :class="slide.retrosnaps.length>0 ? 'retrosnapImg fadingEffect' : ''"
                                   :src="slide.retrosnaps.length > 0 ? entrypoint+'/thumbnail/list/'+slide.retrosnaps[slide.retrosnaps.length-1].filePath.replace(/\.jpeg/,(webpSupported ? '.webp' : '.jpeg')) : entrypoint+'/thumbnail/list/'+slide.filePath.replace(/\.jpeg/,(webpSupported ?'.webp' : '.jpeg'))"
                                   :lazy-src="slide.retrosnaps.length>0 ? entrypoint+'/thumbnail/lazy/'+slide.retrosnaps[slide.retrosnaps.length-1].filePath : entrypoint+'/thumbnail/lazy/'+slide.filePath.replace(/\.jpeg/,(webpSupported ?'.webp' : '.jpeg'))"
                            ></v-img>

                        </v-img>

                        <Viewer25D
                            v-if="slide.retrosnaps.length===0 && slide.depthMapPath && show25DPhotos"
                            :imageOriginal="entrypoint+'/thumbnail/'+(showColorized ? 'colorized.' : '')+'list/'+slide.filePath"
                            :imageLazy="entrypoint+'/thumbnail/'+(showColorized ? 'colorized.' : '')+'lazy/'+slide.filePath"
                            :imageDepth="entrypoint+'/thumbnail/list/'+slide.depthMapPath"
                            :aspectRatio="getRatioRounded(slide)"
                            :horizontalThreshold="45"
                            :verticalThreshold="45"></Viewer25D>
                        <div class="retrosnapOverlay"
                             @dblclick.stop="carouselItemClick(slide['@id'])"
                             @click.stop="carouselItemClick(slide['@id'])"
                             v-touch="{ left: () => setActiveTouchEle(slide),  right: () => setActiveTouchEle(slide), up: () => setActiveTouchEle(slide), down: () => setActiveTouchEle(slide)}"
                        >
                            <v-card-actions

                                transition="fade-transition"
                                class="homevcardactions">
                                <v-spacer></v-spacer>

                                <v-tooltip top
                                           v-if="personItem && item && item.owner && personItem['@id']===item.owner['@id'] && item['@type']==='https://schema.org/ImageGallery'">
                                    <template v-slot:activator="{ on: onTooltip }">
                                        <v-btn
                                            @click.native.stop.prevent="removePhotographFromGallery(slide)"
                                            v-on="onTooltip"
                                               icon>
                                            <v-icon>mdi-image-remove</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>{{ $t('app.Gallery.RemoveFromGallery') }}</span>
                                </v-tooltip>
                                <v-btn v-if="typeof slide.retrosnaps !=='object' && slide.retrosnaps.length>1"
                                       @click.native.stop.prevent=""
                                       :to="{name: 'PhotographRetrosnaps'+ (showColorized ? 'Color' : ''), params: {id: slide['id']}}" icon>
                                    <v-icon>icon-RetroSnap</v-icon>
                                </v-btn>
                            </v-card-actions>

                        </div>
                        <div v-if="slide.backFilePath" class="flipsideIcon">
                            <img style="width:32px;height:32px" src="/img/icons/FlipSide_Available_complete.svg"
                                 alt="flip"/>
                        </div>


                    </div>
                    <div
                        :class="'masonry-item'+getRatioClass(slide.photograph)"
                        v-for="(slide) in item['retrosnaps']"
                        :key="slide['@id']"

                    >


                        <v-img
                               v-touch="{ left: () => setActiveTouchEle(slide),  right: () => setActiveTouchEle(slide), up: () => setActiveTouchEle(slide), down: () => setActiveTouchEle(slide)}"

                               class="masonry-content"
                               :aspect-ratio="getRatioRounded(slide.photograph)"
                               :src="entrypoint+'/thumbnail/'+(showColorized ? 'colorized.' : '')+'list/'+slide.filePath.replace(/\.jpeg/,(webpSupported ?'.webp' : '.jpeg'))"
                               :lazy-src="entrypoint+'/thumbnail/'+(showColorized ? 'colorized.' : '')+'lazy/'+slide.filePath.replace(/\.jpeg/,(webpSupported ?'.webp' : '.jpeg'))"

                               @dblclick.stop="carouselItemClick(slide['@id'])"
                               @click.stop="carouselItemClick(slide['@id'])"
                        >
                            <v-img
                                   :class="'retrosnapImg fadingEffect'"
                                   :src="entrypoint+'/thumbnail/list/'+slide.photograph.filePath.replace(/\.jpeg/,(webpSupported ? '.webp' : '.jpeg'))"
                                   :lazy-src="entrypoint+'/thumbnail/lazy/'+slide.photograph.filePath"
                            ></v-img>
                        </v-img>
                    </div>
                    <div
                        :class="'masonry-item'+getRatioClass(slide)"
                        v-for="(slide) in photographComments"
                        :key="slide['@id']"

                    >
                        <v-img
                            class="masonry-content"
                            :aspect-ratio="getRatioRounded(slide)"
                            :src="entrypoint+'/comments/'+slide.id+'/showThumbnail/'+(showColorized ? 'colorized.' : '')+'list'+(webpSupported ?'.webp' : '.jpeg')"
                            :lazy-src="entrypoint+'/comments/'+slide.id+'/showThumbnail/'+(showColorized ? 'colorized.' : '')+'lazy'+(webpSupported ?'.webp' : '.jpeg')"
                            @dblclick.stop="photographCommentClick(slide)"
                            @click.stop="photographCommentClick(slide)"
                            @mouseover="() => setActiveTouchEle(slide)"
                            v-touch="{ left: () => setActiveTouchEle(slide),  right: () => setActiveTouchEle(slide), up: () => setActiveTouchEle(slide), down: () => setActiveTouchEle(slide)}"
                        >
                            <v-card-text class="homevcarddescription"
                                         v-if="showGalleryInfosOverlay || activeTouchEle===slide"
                                         transition="fade-transition"
                                         v-html="slide.text"
                            >
                            </v-card-text>
                        </v-img>
                        <v-card-actions
                            v-if="showGalleryInfosOverlay || activeTouchEle===slide"
                            transition="fade-transition"
                            class="homevcardactions">
                            <v-spacer></v-spacer>

                            <v-tooltip top
                                       v-if="personItem && item.owner && personItem['@id']===item.owner['@id'] && item && item['@type']==='https://schema.org/ImageGallery'">
                                <template v-slot:activator="{ on: onTooltip }">
                                    <v-btn
                                        @click.native.stop.prevent="removeCommentFromGallery(slide)"
                                        v-on="onTooltip"
                                        icon>
                                        <v-icon>mdi-image-remove</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('app.Gallery.RemoveFromGallery') }}</span>
                            </v-tooltip>
                        </v-card-actions>
                    </div>

                </div>
            </div>
        </div>


        <v-bottom-sheet inset hide-overlay
                        v-model="showInfo"
                        v-if="showInfo && isActive && item"
                        scrollable
                        class="noBackground"

        >
            <v-card class="bgBlur">
                <v-btn top right absolute icon @click="showInfo = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-card-title
                    class="text-h5 noBackground"
                    primary-title
                >
                    {{commentItems.length}} {{ $t('app.Comments') }}
                </v-card-title>
                <v-card-text ref="commentsContainer">
                    <comment-item
                        v-for="commentItem in commentItems"
                        hover
                        elevation="2"
                        class="my-2"
                        :color="(activeComment===commentItem.id ? 'Active' : '')"
                        :id="'comment_'+commentItem.id"
                        :ref="'comment_'+commentItem.id"
                        :comment-item="commentItem"
                        :comment-click="commentClick"
                        :active-comment="activeComment"
                        :edit-comment="editComment"
                        :delete-comment="deleteComment"
                        :upvote-comment="upvoteComment"
                        :downvote-comment="downvoteComment"
                        :report-inappropriate-comment="reportInappropriateComment"
                        :key="commentItem.id">

                    </comment-item>

                </v-card-text>
                <v-card-actions v-if="item" >
                    <v-btn
                        rounded
                        outlined
                        block
                        style="margin-bottom:7px; pointer-events: stroke;"
                        @click="addComment()"
                    >
                        <v-row>
                            <v-col class="text-capitalize text-left">{{$t('app.Comment.Comment')}}...</v-col>
                            <v-col class="text-right">
                                <v-icon>
                                    mdi-comment-plus
                                </v-icon>
                            </v-col>
                        </v-row>


                    </v-btn>

                </v-card-actions>
            </v-card>
        </v-bottom-sheet>

        <div v-if="offsetTop>stickyTitleShowPos && item" class="stickytitle"><h1 class="mb-2">{{ item && item['headline'] }}</h1></div>
        <v-footer class="imagegalleryFooter font-italic font-weight-thin" v-if="item">
            <ShareButton v-if="metaUrl"
                :url="metaUrl"
                :title="metaTitle"
                :description="metaDescription"
                quote="Schau dir dieses Album auf Nimmerso an"
                :hashtags="'nimmerso,'+metaHashtags.join(',')"
                :media="metaImage"
                twitterUser="nimmerso"
            ></ShareButton>
            <v-tooltip left style="transition-delay: 0.15s;">
                <template v-slot:activator="{ on: onTooltip }">
                    <v-btn @click.native.stop.prevent="showGallerySelectDialog()" icon v-on="{...onTooltip}" ><v-icon>mdi-notebook-plus</v-icon></v-btn>
                </template>
                <span>{{ $t('app.Gallery.Add') }}...</span>
            </v-tooltip>

            <v-tooltip top
                       v-if="personItem && item && item.owner && personItem['@id']===item.owner['@id'] && item['@type']==='https://schema.org/ImageGallery'">
                <template v-slot:activator="{ on: onTooltip }">
                    <v-btn :to="{name: 'ImageGalleryUpdate', params: { id: item['@id'] }}" v-on="onTooltip"
                           icon>
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                </template>
                <span>{{ $t('app.Edit') }}</span>
            </v-tooltip>
            <v-tooltip top
                       v-if="personItem && item.owner && personItem['@id']===item.owner['@id'] && item && item['@type']==='https://schema.org/ImageGallery'">
                <template v-slot:activator="{ on: onTooltip }">
                    <v-btn @click="deleteItem(item)" v-on="onTooltip" icon color="error">
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                </template>
                <span>{{ $t('app.Delete') }}</span>
            </v-tooltip>
            <v-row >
                <v-col xs="10" sm="10" md="8" lg="6" xl="4">
                    <v-btn
                        rounded
                        outlined
                        block
                        style="margin-bottom:7px; pointer-events: stroke;"
                        @click="addComment()"


                    >
                        <v-row>
                            <v-col class="text-capitalize text-left">{{$t('app.Comment.Comment')}}...</v-col>
                            <v-col class="text-right">
                                <v-icon>
                                    mdi-comment-plus
                                </v-icon>
                            </v-col>
                        </v-row>


                    </v-btn>
                </v-col>
                <v-col cols="2" xs="2" sm="2" md="4" lg="6" xl="8">
                    <v-tooltip top style="transition-delay: 0.15s;" v-if="item.comments.length>0">
                        <template v-if="item.comments.length>0" v-slot:activator="{ on: onTooltip }">
                            <v-badge

                                :content="item.comments.length"
                                :value="item.comments.length"
                                offset-x="16"
                                offset-y="16"
                                overlap
                            >
                                <v-btn @click.stop="showInfo=!showInfo" v-on="onTooltip" icon style="pointer-events: stroke;" >
                                    <v-icon>icon-SpeechBubble</v-icon>
                                </v-btn>
                            </v-badge>


                        </template>
                        <span>{{ $t('app.Photograph.ShowComments') }}</span>
                    </v-tooltip>
                    <v-tooltip v-else top style="transition-delay: 0.15s;" >
                        <template v-slot:activator="{ on: onTooltip }">
                            <v-btn  @click.stop="showComments=true" v-on="onTooltip" icon style="pointer-events: stroke;" >
                                <v-icon>icon-SpeechBubble</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('app.Photograph.ShowComments') }}</span>
                    </v-tooltip>
                </v-col>
            </v-row>
        </v-footer>
        <v-bottom-sheet
            inset
            hide-overlay
            scrollable
            style="z-index:10001;"
            v-if="item && showCommentDialog" v-model="showCommentDialog"
        >
            <comment-dialog
                :do-comment-dialog-close="doCommentDialogClose"
                :edit-rectangle="null"
                :new-comment="newComment"
                :on-send-comment-form="onSendCommentForm"
                :update-comment-field="updateCommentField"
                :submit-comment="submitComment"
            >
            </comment-dialog>
        </v-bottom-sheet>
        <v-dialog
            v-if="showCommentLoginDialog"
            v-model="showCommentLoginDialog"
            transition="dialog-bottom-transition"
            max-width="600"
        >
            <v-card class="text-center">
                <v-toolbar
                >{{ $t('app.Comment.PleaseLogIn') }}</v-toolbar>
                <v-card-title class="text-h5">

                    <v-spacer></v-spacer>
                    <v-btn top right absolute icon @click="showCommentLoginDialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>{{ $t('app.Comment.LoginText') }}<br/>
                {{ $t('app.Comment.LoginText2') }}</v-card-text>
                <v-card-actions><v-spacer></v-spacer><v-btn @click="goToStart">{{ $t('app.Comment.LoginRegister') }}</v-btn><v-spacer></v-spacer></v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-if="item" v-model="gallerySelectDialog" max-width="400">
            <v-card>
                <v-card-title>{{ $t('app.Gallery.Add')}}</v-card-title>
                <v-card-text>
                    <v-autocomplete
                        value=""
                        :label="$t('app.Gallery.Select')"
                        :items="imagegalleries.filter(g => g['@id']!==item['@id'])"
                        item-value="@id"
                        item-text="headline"
                        v-model="selectedGallery"
                        dense
                        filled
                        :placeholder="$t('app.Gallery.Select')"
                    >
                        <template v-slot:item="data">
                            <template>
                                <v-list-item-avatar
                                    tile
                                    size="64"
                                >
                                    <v-img
                                        :src="entrypoint+'/image_galleries/'+data.item['thumbnail']+'/icon'"

                                    ></v-img>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title v-html="data.item.headline"></v-list-item-title>
                                    <v-list-item-subtitle v-html="data.item.group"></v-list-item-subtitle>
                                </v-list-item-content>
                            </template>
                        </template>

                    </v-autocomplete>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        :to="{ name: 'ImageGalleryCreate' }"
                        color="secondary"
                        plain
                    >
                        <v-icon>mdi-plus</v-icon>
                        {{ $t('app.Gallery.Create') }}
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        plain
                        text
                        @click="selectGallery"
                    >
                        {{$t('app.Gallery.AddShort')}}
                    </v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>
    </v-card>
</template>
<style>
.album-text {
    hyphens: auto;
}
.imagegalleryFooter{
    position: fixed;
    bottom: 0px;
    right: 0;
    left: 0;
    opacity: 0.7;
}

.albumThumbnail{
    position: fixed;
    top:0;
    left:0;
    right:0;
    text-align: center;
}
.albumThumbnail img{
    /*transition: width 50ms, height 50ms*/
}
.albumCard{
    top: min(100vw,100vh);
    margin-bottom: min(100vw,100vh);
}

.album{
    transition: width 500ms, height 500ms
}
.stickytitle{
    position:fixed;
    top:0;
    left:0;
    right:0;
    padding:0 8px 0 8px;
    background: linear-gradient(180deg, #2A2A33 0%, rgba(0,0,0,1) 50%, rgba(0,0,0,0) 100%);
}

.album p {
    margin-bottom: 0 !important;
}
.album-description {
    color: #ded8fe;
}

</style>
<script>
import { ENTRYPOINT } from '@/config/entrypoint'
import isWebpSupported from '../../utils/isWebpSupported'
import { mapActions, mapGetters } from 'vuex'
import Viewer25D from '../viewer25d/Viewer25D'
import fetch from '@/utils/fetch'
import CommentItem from '../comment/CommentItem'
import CommentDialog from '../comment/CommentDialog'
import ShareButton from '../share/ShareButton'
import { Scroll } from 'vuetify/lib/directives'

export default {
    components: {
        CommentItem,
        CommentDialog,
        ShareButton,
        Viewer25D
    },
    directives: {
        Scroll
    },
    computed:{
        ...mapGetters({
                       deleteError: 'imagegallery/del/error',
                       //error: 'imagegallery/show/error',
                       isLoading: 'imagegallery/show/isLoading',
                       item: 'imagegallery/show/retrieved',
                       settings: 'settings/items',
                       personItem: 'person/show/retrieved',
                       token: 'user/token/token',
                       refresh_token: 'user/token/refresh_token',
                       token_data: 'user/token/token_data',
                       commentDeletedItem: 'comment/del/deleted',
                       commentError: 'comment/list_imagegallery/error',
                       commentItems: 'comment/list_imagegallery/items',
                       commentIsLoading: 'comment/list_imagegallery/isLoading',
                       commentView: 'comment/list_imagegallery/view',
                       commentCreated: 'comment/create/created',
                       commentRetrieved: 'comment/update/retrieved',
                       commentUpdated: 'comment/update/updated',
                       commentCreateViolentations: 'comment/create/violentations',
                       categories: 'category/list/items'
                   }),
        metaDescription() {
            return this.$t('app.MetaDescription');
        },
    },
    watch: {
        settings () {
            this.showColorized = this.settings.showColorized
            this.showGalleryInfosOverlay = this.settings.showInfosOverlay
            this.show25DPhotos = typeof this.settings.show25DPhotos != 'undefined' ? this.settings.show25DPhotos : true
        },
        '$route.params.id': function (id) {
            window.console.log('watch $route.params.id ', this.$route.name);
            if(this.oldId===id) return;
            this.reset();
            if(!this.$route.name.startsWith('ImageGalleryShow')) return;


            this.oldId=id;
            //this.reset()
            //if (id) this.retrieve(decodeURIComponent(id))
            this.retrieve(decodeURIComponent(this.$route.params.id))
            this.checkActiveComment()
            this.onScroll();
        },
        '$route.params.comment_id': function () {
            this.checkActiveComment()
            if(this.$route.name!=='ImageGalleryShow') return;

        },
        personItem: function () {
            this.imagegalleries = this.personItem.imageGalleries
        },
        item: function () {
            if (this.item === [] || this.item === null) return
            //this.isActive=true;
            this.metaItem = this.item
            this.metaTitle = this.metaItem.headline + ' - Nimmerso'
            this.metaDescription = this.htmlAsText(this.metaItem.description)
            this.metaImage = this.entrypoint + '/image_galleries/' + this.metaItem.thumbnail + '/list.jpeg'
            this.metaUrl = this.getCompleteUrl()
            this.metaHashtags = []
            if (this.categories) {
                this.metaHashtags = this.categories.filter((category) => this.metaItem.categories.indexOf(category['@id']) !== -1).map(category => category.name)
            }

            this.getComments('/comments?imageGallery=' + this.$route.params.id)
            this.getPhotographComments()
            /*if(this.offsetTop){
                window.console.log('offsetTop', this.offsetTop);
                window.scrollTo(0,this.offsetTop);
            }*/
            this.onScroll();
            //this.getComments('/comments?galleries=' + this.$route.params.id).then(result=>{window.console.log(result)})

        },
        commentItems: function () {
            this.checkActiveComment()

        },
        categories: function () {
            this.metaHashtags = []
            if (this.item) {
                this.metaHashtags = this.categories.filter((category) => this.metaItem.categories.indexOf(category['@id']) !== -1).map(category => category.name)
            }
            this.updateAttributes()
        },
        offsetTop: function() {


        }
    },
    data: () => ({
        entrypoint: ENTRYPOINT,
        showColorized: false,
        showGalleryInfosOverlay: false,
        showInfo: false,
        show25DPhotos: true,
        webpSupported: true,
        imagegalleries: [],
        activeComment: null,
        showCommentDialog: false,
        newComment: {box: null, isPrivate: false},
        oldScrollPos: 0,
        metaItem: {},
        metaTitle: 'Nimmerso - am selben Ort, zu andrer Zeit',
        metaUrl: null,
        metaImage: null,
        metaHashtags: [],
        attributes: [],
        photographComments: [],
        activeTouchEle: null,
        albumThumbnailHeight: window.innerHeight,
        albumThumbnailTop: 20,
        albumThumbnailOpacity: 1,
        offsetTop: 0,
        isActive: false,
        oldId: null,
        showAll: false,
        stickyTitleShowPos: Math.min(window.innerHeight,window.innerWidth),
        showCommentLoginDialog: false,
        error: null,
        selectedGallery: null,
        gallerySelectDialog: false,

    }),
    metaInfo() {
        return {
            title: this.metaTitle,
            meta: [
                {
                    name: 'description',
                    content: this.metaDescription,
                },
            ],
        };
    },
    beforeDestroy () {
        //window.console.log('beforeDestroy',this.isActive);
        this.isActive=false;
        this.reset()
    },
    deactivated () {
        //window.console.log('deactivated',this.isActive);
        this.isActive=false;
        //this.reset()
        this.showGalleryInfosOverlay=false;
    },
    activated () {
        //window.console.log('activated',this.isActive);
        this.isActive=true;
        if(this.offsetTop){
            //window.console.log('offsetTop', this.offsetTop, window.scrollMaxY );
            //window.scrollTo(0,this.offsetTop);
            setTimeout(() => {
                window.scrollTo(0,this.offsetTop);
                //window.console.log('offsetTop', this.offsetTop, window.scrollMaxY);
            }, 500)
        }
        /*setTimeout(() => {
            this.onScroll();
        }, 500)

         */

    },

    created () {
        //window.console.log('created',this.isActive);
        this.isActive=true;

        this.webpSupported = isWebpSupported()
        this.retrieve(decodeURIComponent(this.$route.params.id))
        //this.showColorized = this.settings.showColorized
        this.showColorized=this.$route.path.indexOf('/color')!==-1;
        this.showGalleryInfosOverlay = this.settings.showInfosOverlay
        this.show25DPhotos = this.settings.show25DPhotos
        if (this.token_data) {
            this.personRetrieve('/people/' + this.token_data.person_id)
        } else {
            window.console.log('no tokenData!')
        }
        this.getCategories()
        this.onScroll();
    },
    mounted () {
        //window.console.log('mounted',this.isActive);
        this.isActive=true;
        this.onScroll();
    },
    methods: {
        ...mapActions({
            del: 'imagegallery/del/del',
            reset: 'imagegallery/show/reset',
            retrieve: 'imagegallery/show/retrieve',
            updateImageGallery: 'imagegallery/update/update',
            updateRetrievedImageGallery: 'imagegallery/update/updateRetrieved',
            retrieveImageGallery: 'imagegallery/update/retrieve',
            personRetrieve: 'person/show/retrieve',
            gettokendata: 'user/token/gettokendata',
            createComment: 'comment/create/create',
            getComments: 'comment/list_imagegallery/default',
            getCategories: 'category/list/default'
        }),

        setRedirectRoute(route) {
            window.console.log('setRedirectRoute',route);
            this.$store.commit('settings/setsettings', Object.assign({}, this.settings, {redirectRoute: route}))
        },
        goToStart(){
            this.showLoginDialog=false;
            this.setRedirectRoute(this.$route.fullPath);
            this.$router.push({path: '/login'})
        },
        getTranslation (item) {
            let transList = item['translations'].filter((t) => t.locale === this.$root.$i18n.locale)
            return typeof transList[0] === 'undefined' ? item['translations'][0] : transList[0]
        },
        carouselItemClick (item) {
            //window.console.log(item.substr(0,11));
            if(item.substr(0,11)==='/retrosnaps'){
                this.$router.push({name: 'RetrosnapShow', params: {id: item}});
            }else if(item.substr(0,16)==='/image_galleries'){
                this.$router.push({name: 'ImageGalleryShow', params: {id: item}});
            }else{
                this.$router.push({name: 'PhotographShow'+ (this.showColorized ? 'Color' : ''), params: {id: item}});
            }

        },
        deleteItem (item) {
            if (window.confirm('Are you sure you want to delete this item?')) {
                this.del(item).then(() => this.$router.push({name: 'ImageGalleryList'}))
            }
        },
        addToGallery (imagegallery, slide) {
            let ig = JSON.parse(JSON.stringify(imagegallery))
            ig.photographs.push(slide)
            ig.photographs = [...new Set(ig.photographs)]
            //this.updateRetrievedImageGallery({ photographs: imagegallery.photographs })
            this.updateGallery(ig)
        },
        removePhotographFromGallery (slide) {
            if (window.confirm(this.$t('app.Gallery.DeleteConfirm'))) {
                let ig = JSON.parse(JSON.stringify(this.item))
                let photographs = [];
                ig.photographs.forEach((photograph) => {
                    if (photograph['@id'] !== slide['@id']) photographs.push(photograph);
                });
                ig.photographs = [...new Set(photographs)]
                this.updateGallery(ig)
            }
        },
        removeGalleryFromGallery (slide) {
            if (window.confirm(this.$t('app.Gallery.DeleteConfirm'))) {
                let ig = JSON.parse(JSON.stringify(this.item))
                let subGalleries = [];
                ig.subGalleries.forEach((subGallery) => {
                    if (subGallery['@id'] !== slide['@id']) subGalleries.push(subGallery);
                });
                ig.subGalleries = [...new Set(subGalleries)]
                this.updateGallery(ig)
            }
        },
        removeCommentFromGallery (commentItem) {
            if (window.confirm(this.$t('app.Gallery.DeleteConfirm'))) {
                const galleries = [...commentItem.galleries].filter((item) => {
                    return item !== this.item['@id']
                })
                //window.console.log(commentItem['@id'])
                //window.console.log(commentItem.galleries);
                //window.console.log(galleries)
                //this.updateCommentField('galleries',galleries)
                Object.assign(commentItem, {'galleries': galleries})
                this.updatePhotographComment(commentItem)
            }
        },
        updateGallery (gallery) {

            let ig = JSON.parse(JSON.stringify(gallery))
            //window.console.log(typeof ig.retrosnaps, ig.retrosnaps);
            if(typeof ig.retrosnaps!=='undefined') ig.retrosnaps=this.arrayToIris(ig.retrosnaps);
            if(typeof ig.subGalleries!=='undefined') ig.subGalleries=this.arrayToIris(ig.subGalleries);
            if(ig.parentGalleries) delete ig.parentGalleries;
            return fetch(ig['@id'], {
                method: 'PUT',
                headers: new Headers({'Content-Type': 'application/ld+json'}),
                body: JSON.stringify(ig)
            })
                .then(response => response.json())
                .then(() => {
                    //window.console.log(data)
                    this.retrieve(decodeURIComponent(this.$route.params.id))
                })
                .catch((e) => {

                    return e

                })
        },
        arrayToIris(datas){
            if(!datas || typeof datas === 'undefined' || datas.length==null) return null;
            let iriArray=[];
            datas.forEach((data)=>{
                if(typeof data =='object' && typeof data['@id']!=='undefined') {
                    iriArray.push(data['@id']);
                }else if (typeof data == 'string'){
                    iriArray.push(data);
                }
            });
            return iriArray;
        },
        onSendCommentForm (commentData) {
            this.newComment = commentData
            if (this.newComment.id == null) {
                this.createComment(this.newComment)
            } else {
                this.updateComment(this.newComment)
            }

        },
        updateCommentField (field, value) {
            //window.console.log(field, value)
            Object.assign(this.newComment, {[field]: value})
        },
        addComment () {
            if(this.token == null){
                //not logged in:
                this.showCommentLoginDialog = true;
                return;
            }
            this.showCommentDialog = true
            this.newComment = {
                box: null,
                text: '',
                isPrivate: false,
                'imageGallery': this.item['@id'],
                isBackside: false
            }
        },
        commentDialogClose () {
            this.pathEditMode = false
            this.box = null
        },
        doCommentDialogClose () {
            this.showCommentDialog = false
            this.commentDialogClose()
        },
        editComment (commentItem) {
            this.showInfo = false
            this.newComment = commentItem
            this.showCommentDialog = true
        },
        deleteComment (commentItem) {
            if (window.confirm(this.$t('app.Comment.DeleteConfirm'))) {
                return fetch(commentItem['@id'], {
                    method: 'DELETE',
                    headers: new Headers({'Content-Type': 'application/ld+json'}),
                    body: JSON.stringify(commentItem)
                })
                    .then(() => {
                        this.getComments('/comments?imageGallery=' + this.$route.params.id)
                        //window.console.log('deleted')
                    })
                    .catch((e) => {
                        this.setError(e)
                        return e

                    })
            }
        },
        reportInappropriateComment (commentItem) {
            window.console.log('reportInappropriateComment', commentItem)
        },
        submitComment () {
            this.showCommentDialog = false
            this.commentDialogClose()
            if (this.newComment['@id']) {
                this.updateComment(this.newComment)
            } else {
                this.createComment(this.newComment).then(() => {
                    this.getComments('/comments?imageGallery=' + this.$route.params.id)
                })
                    .catch((e) => {
                        window.console.log(e)
                        this.setError(e)
                        return e

                    })
            }

        },
        updateComment (comment) {

            return fetch(comment['@id'], {
                method: 'PUT',
                headers: new Headers({'Content-Type': 'application/ld+json'}),
                body: JSON.stringify(comment)
            })
                .then(response => response.json())
                .then(() => {
                    this.getComments('/comments?imageGallery=' + this.$route.params.id)
                })
                .catch((e) => {
                    window.console.log(e)
                    this.setError(e)
                    return e

                })
        },
        updatePhotographComment (comment) {

            return fetch(comment['@id'], {
                method: 'PUT',
                headers: new Headers({'Content-Type': 'application/ld+json'}),
                body: JSON.stringify(comment)
            })
                .then(response => response.json())
                .then(() => {
                    this.getPhotographComments()
                })
                .catch((e) => {
                    window.console.log(e)
                    this.setError(e)
                    return e

                })
        },
        commentClick (commentItem) {

            //if (this.activeComment===commentItem.id) return;
            this.oldScrollPos = window.scrollY
            let routeParams = {id: this.item['@id'], comment_id: commentItem.id}
            if (this.$route.params.id !== routeParams.id || this.$route.params.comment_id !== routeParams.comment_id) this.$router.replace({
                name: 'ImageGalleryShowComment',
                params: routeParams,
                //hash: 'comment_'+routeParams.comment_id
            })

        },
        upvoteComment (commentItem) {
            return fetch(commentItem['@id'] + '/vote/up', {
                method: 'PUT',
                headers: new Headers({'Content-Type': 'application/ld+json'}),
                body: JSON.stringify({})
            })
                .then(response => response.json())
                .then(() => {
                    this.getComments('/comments?imageGallery=' + this.$route.params.id)
                })
                .catch((e) => {
                    this.setError(e)
                    return e

                })
        },
        downvoteComment (commentItem) {
            return fetch(commentItem['@id'] + '/vote/down', {
                method: 'PUT',
                headers: new Headers({'Content-Type': 'application/ld+json'}),
                body: JSON.stringify({})
            })
                .then(response => response.json())
                .then(() => {
                    this.getComments('/comments?imageGallery=' + this.$route.params.id)
                })
                .catch((e) => {
                    this.setError(e)
                    return e

                })
        },
        checkActiveComment () {
            //window.console.log('checkActiveComment')
            if (typeof this.$route.params.comment_id !== 'undefined') {
                this.activeComment = decodeURIComponent(this.$route.params.comment_id)
                if (this.activeComment === false) this.activeComment = null
                this.showInfo = true

                setTimeout(() => {
                    if (this.activeComment && this.$refs['comment_' + this.activeComment]) {
                        if (this.scrollY > 0) window.scrollY = this.scrollY
                        //this.$vuetify.goTo(this.$refs['comment_' + this.activeComment][0], {});

                    }
                }, 1000)

            }
        },
        getCompleteUrl: function () {
            return window.location.origin + this.$route.fullPath
        },
        htmlAsText (value) {

            return (typeof value == 'undefined' || value == null) ? '' : value.replace(/<\/?[^>]+(>|$)/g, '')
        },
        getCategoryById (id) {
            return this.categories.filter(category => category['@id'] === id)[0]
        },
        setCategoryFilter (category) {
            window.console.log(category)
        },
        updateAttributes () {
            let attributesObj = {}
            if (this.categories.length > 0) {
                this.categories.forEach((catObject) => {
                    if (typeof (catObject) !== 'undefined') {
                        const translation = this.getTranslation(catObject)
                        if (typeof translation.attributesDefinition === 'object' && translation.attributesDefinition !== null) {
                            translation.attributesDefinition.forEach((aD) => {
                                attributesObj[aD.code] = aD
                            })
                        }
                    }
                })
            }
            this.attributes = []
            for (let key in attributesObj) {
                if (Object.prototype.hasOwnProperty.call(attributesObj,key)) {
                    this.attributes.push(attributesObj[key])
                }
            }
        },
        getPhotographComments () {
            return fetch('/comments?galleries=' + this.$route.params.id)
                .then(response => response.json())
                .then((data) => {
                    this.photographComments = data['hydra:member']
                })
                .catch((e) => {
                    window.console.error(e)
                })
        },
        photographCommentClick (slide) {
            this.$router.push({
                name: 'PhotographShowComment'+ (this.showColorized ? 'Color' : ''),
                params: {id: slide['photograph'], comment_id: slide['id']},
            })
            //window.console.log(slide)
        },
        setActiveTouchEle (item) {
            //console.log('setActiveTouchEle',item)
            this.activeTouchEle = item
        },
        getRatioRounded(item){
            let ratio = 1;
            if(item.thumbnailCoordinates && item.thumbnailCoordinates.feed ){
                ratio = item.thumbnailCoordinates.feed.width / item.thumbnailCoordinates.feed.height;
            }else if(item.ratio ){
                ratio = item.ratio;
            }
            if(ratio>1.5){
                return 2;
            }else if(ratio<0.8){
                return 0.5;
            }
            return 1;
        },
        getRatioClass(item){
            let miClass='';
            let ratio = 1;
            if(item.documentType==='ImageGallery'){
                miClass=' mi-small' ;
                if (item.showBig){
                    miClass=' mi-big';
                }
            }else if(item.thumbnailCoordinates && item.thumbnailCoordinates.feed ){
                ratio = item.thumbnailCoordinates.feed.width / item.thumbnailCoordinates.feed.height;
            }else if(item.box ){
                ratio = item.box.width / item.box.height;
            }else if(item.ratio ){
                ratio = item.ratio;
            }
            if (miClass==''){
                if(ratio>1.5){
                    miClass=' mi-horizontal';
                }else if(ratio<0.8){
                    miClass=' mi-vertical';
                }else{
                    miClass=' mi-small';
                }
            }
            return miClass;
        },
        closeWin () {
            this.isActive=false;
            window.history.length > 2 ? this.$router.go(-1) : this.$router.push('/feed')
        },
        onScroll () {
            if(this.isActive===false) return;
            if(window.scrollY>0) this.offsetTop = window.scrollY;
            //window.console.log('onScroll offsetTop', this.offsetTop, this.isActive);
            this.albumThumbnailHeight=Math.min(window.innerHeight,window.innerWidth)-this.offsetTop;
            this.albumThumbnailTop=0;
            this.albumThumbnailOpacity=1;
            if (this.albumThumbnailHeight<100){
                this.albumThumbnailTop=(this.albumThumbnailHeight-100);

                this.albumThumbnailOpacity=Math.min(100,this.albumThumbnailHeight)/100;
                this.albumThumbnailHeight=100;
            }
            if(typeof this.$refs['albumThumbnailImage'] !== 'undefined') this.$refs['albumThumbnailImage'].style='width: '+this.albumThumbnailHeight+'px; height: '+this.albumThumbnailHeight+'px; opacity: '+this.albumThumbnailOpacity;
            if(typeof this.$refs['albumThumbnail'] !== 'undefined') this.$refs['albumThumbnail'].style='top:'+this.albumThumbnailTop+'px';
        },
        setError (message) {
            this.error = message
            this.errorMessage = message
        },
        showGallerySelectDialog(){
            this.gallerySelectDialog=true;
        },
        selectGallery(){
            if (this.selectedGallery===null) return;
            let imagegallery=null;
            this.imagegalleries.forEach((item)=>{
                if (item['@id']===this.selectedGallery){
                    imagegallery=item;
                    return true;
                }
            });

            this.addGalleryToGallery(imagegallery,this.item);

        },
        addGalleryToGallery (imagegallery, item) {
            let ig = JSON.parse(JSON.stringify(imagegallery))
            ig.subGalleries.push(item)
            ig.subGalleries = [...new Set(ig.subGalleries)]
            //this.updateRetrievedImageGallery({ photographs: imagegallery.photographs })
            this.updateGallery(ig)
            this.gallerySelectDialog=false;
        },
    }
}
</script>
